<template>
  <div class="d-flex flex-column flex-root" style="height: 100%">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid "
        style="background-image: url('media/anhbia1.png'); background-position: center;
              background-repeat: no-repeat;
              background-size: cover; "
        :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
        id="kt_login"
    >
      <!--begin::Aside-->
      <!--      <div-->
      <!--        class="login-aside d-flex flex-column flex-row-auto"-->
      <!--        style="background-color: #F2C98A;"-->
      <!--      >-->
      <!--        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">-->
      <!--          <a href="#" class="text-center mb-10">-->
      <!--            <img-->
      <!--              src="media/logos/logo-letter-1.png"-->
      <!--              class="max-h-70px"-->
      <!--              alt=""-->
      <!--            />-->
      <!--          </a>-->
      <!--          <h3-->
      <!--            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"-->
      <!--            style="color: #986923;"-->
      <!--          >-->
      <!--            Discover Amazing Metronic <br />with great build tools-->
      <!--          </h3>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"-->
      <!--          :style="{ backgroundImage: `url(${backgroundImage})` }"-->
      <!--        ></div>-->
      <!--      </div>-->
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pt-lg-0 pt-5 text-center">
                <a href="#" class="text-center mb-10">
                  <img
                      src="media/iconwhite.png"
                      class="max-h-70px mb-15"
                      alt=""
                  />
                </a>
                <div class="mb-20">
                  <h3 class="text-white font-weight-bold">HỆ THỐNG QUẢN TRỊ CMS</h3>
<!--                  <div class="text-white font-weight-bold">Enter your details to login to your account:</div>-->
                </div>
                <!--                <h3-->
                <!--                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"-->
                <!--                >-->
                <!--                  Welcome to Metronic-->
                <!--                </h3>-->
                <!--                <span class="text-muted font-weight-bold font-size-h4"-->
                <!--                >New Here?-->
                <!--                  <a-->
                <!--                      id="kt_login_signup"-->
                <!--                      class="text-primary font-weight-bolder"-->
                <!--                      @click="showForm('signup')"-->
                <!--                  >Create an Account</a-->
                <!--                  ></span-->
                <!--                >-->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-white"
                >Phone</label
                >
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      name="phone"
                      ref="phone"
                      v-model="form.phone"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-white pt-5"
                  >Password</label
                  >
                  <!--                  <a-->
                  <!--                      class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"-->
                  <!--                      id="kt_login_forgot"-->
                  <!--                      @click="showForm('forgot')"-->
                  <!--                  >Forgot Password ?</a-->
                  <!--                  >-->
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                <div class="checkbox-inline">
                  <label class="checkbox m-0 text-white">
                    <input type="checkbox" name="remember">
                    <span></span>Ghi nhớ tài khoản</label>
                </div>
<!--                <a href="javascript:;" id="kt_login_forgot"-->
<!--                   @click="showForm('forgot')" class="text-white text-hover-primary">Forget Password ?</a>-->
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                    style="background: #9B51E0;margin-right: 0 !important;"
                >
                  Đăng nhập
                </button>
<!--                <div class="mt-10">-->
<!--                  <span class="text-white mr-4">Don't have an account yet?</span>-->
<!--                  <a href="javascript:;" id="kt_login_signup"-->
<!--                     @click="showForm('signup')" class="text-white text-hover-primary font-weight-bold">Sign Up!</a>-->
<!--                </div>-->
                <!--                <button-->
                <!--                  type="button"-->
                <!--                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"-->
                <!--                >-->
                <!--                  <span class="svg-icon svg-icon-md">-->
                <!--                    <inline-svg-->
                <!--                      src="media/svg/social-icons/google.svg"-->
                <!--                    /> </span-->
                <!--                  >Sign in with Google-->
                <!--                </button>-->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
                @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pt-lg-0 pt-5 text-center">
                <a href="#" class="text-center mb-10">
                  <img
                      src="media/logos/logo-letter-1.png"
                      class="max-h-70px mb-15"
                      alt=""
                  />
                </a>
                <div class="mb-20">
                  <h3>Sign Up</h3>
                  <div class="text-muted font-weight-bold">Enter your details to create your account</div>
                </div>
                <!--                <h3-->
                <!--                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"-->
                <!--                >-->
                <!--                  Welcome to Metronic-->
                <!--                </h3>-->
                <!--                <span class="text-muted font-weight-bold font-size-h4"-->
                <!--                >New Here?-->
                <!--                  <a-->
                <!--                      id="kt_login_signup"-->
                <!--                      class="text-primary font-weight-bolder"-->
                <!--                      @click="showForm('signup')"-->
                <!--                  >Create an Account</a-->
                <!--                  ></span-->
                <!--                >-->
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Fullname"
                    name="fullname"
                    ref="fullname"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="phone"
                    placeholder="Phone"
                    name="phone"
                    ref="rphone"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref="rpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Confirm password"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree"/>
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 flex-center">
                <button
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    style="width:150px;"
                >
                  Submit
                </button>
                <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                ref="kt_login_forgot_form"
            >
              <div class="pt-lg-0 pt-5 text-center">
                <a href="#" class="text-center mb-10">
                  <img
                      src="media/logos/logo-letter-1.png"
                      class="max-h-70px mb-15"
                      alt=""
                  />
                </a>
                <div class="mb-20">
                  <h3>Forgotten Password ?</h3>
                  <div class="text-muted font-weight-bold">Enter your phone to reset your password</div>
                </div>
                <!--                <h3-->
                <!--                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"-->
                <!--                >-->
                <!--                  Welcome to Metronic-->
                <!--                </h3>-->
                <!--                <span class="text-muted font-weight-bold font-size-h4"-->
                <!--                >New Here?-->
                <!--                  <a-->
                <!--                      id="kt_login_signup"-->
                <!--                      class="text-primary font-weight-bolder"-->
                <!--                      @click="showForm('signup')"-->
                <!--                  >Create an Account</a-->
                <!--                  ></span-->
                <!--                >-->
              </div>

              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="phone"
                    placeholder="Phone"
                    name="phone"
                    autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 flex-center">
                <button
                    type="button"
                    id="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!--        <div-->
        <!--          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"-->
        <!--        >-->
        <!--          <a href="#" class="text-primary font-weight-bolder font-size-h5"-->
        <!--            >Terms</a-->
        <!--          >-->
        <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--            >Plans</a-->
        <!--          >-->
        <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--            >Contact Us</a-->
        <!--          >-->
        <!--        </div>-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import store from "@/core/services/store";
import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, LOGOUT, REGISTER} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import BaseService from "@/core/helper/base.service";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        // phone: "0967273891",
        // password: "1234567"
        phone: "",
        password: ""
      }
    };
  },
  created() {
    this.removeLocal()
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
          process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        phone: {
          validators: {
            notEmpty: {
              message: "Số điện thoại không được để trống"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Mật khẩu không được để trống"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        phone: {
          validators: {
            notEmpty: {
              message: "Phone is required"
            },
            phoneAddress: {
              message: "The value is not a valid phone address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        phone: {
          validators: {
            notEmpty: {
              message: "Phone is required"
            },
            phoneAddress: {
              message: "The value is not a valid phone address"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      let number = 0

      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        let username = this.form.phone;
        let password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // console.log(username, password);

          // send login request
          this.$store
              .dispatch(LOGIN, {username, password})
              // go to which page after successfully login
              .then(() => {
                console.log()
                if (JSON.parse(JwtService.getUser()).role === 'AGENCY') {
                  if (this.$route.name !== "emptyRoom") this.$router.push({name: "emptyRoom"})
                } else if (this.$route.name !== "dashboard")
                {
                  console.log(1111)
                  this.$router.push({name: "dashboard"})
                }
              })
              .catch((err) => {
                // console.log('err', err)
              });
          store.subscribe((mutation, state) => {
            if (state.auth.errors) {

              number++
              if (number === 1 && typeof state.auth.errors === 'string') {
                BaseService.makeToast('danger', state.auth.errors, this)
              }
            }

            // called after every mutation.
            // The mutation comes in the format of `{ type, payload }`.
          })
          submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
          );
        }, 1000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const phone = this.$refs.rphone.value;
        const password = this.$refs.rpassword.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
              .dispatch(REGISTER, {
                phone: phone,
                password: password
              })
              .then(() => this.$router.push({name: "dashboard"}));

          submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },
    removeLocal() {
      localStorage.removeItem('id_token');
      localStorage.removeItem('id_user');
    },
  }
};
</script>
